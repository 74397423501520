<template>
  <HeaderBar></HeaderBar>
  <QrStream @decode="onDecode" />
</template>

<script>
// TO DO: find 'QR scan' package for Vue 3 with TypeScript setup
import { QrStream } from 'vue3-qr-reader'
import HeaderBar from '@/components/shared/Header'

export default {
  name: 'ScanQR',
  components: {
    QrStream,
    HeaderBar
  },
  methods: {
    onDecode (result) {
      this.$router.push(`/bin/${result}`)
    }
  }
}
</script>
